<template>
	<div class="local-video">
		<head-image class="head-image" v-show="!userInfo.isCamera" 
			:url="userInfo.headImage" :name="userInfo.nickName" :width="width" :height="height" :radius="'0'">
		</head-image>
		<video v-show="userInfo.isCamera" class="video" ref="video"></video>
	</div>
</template>

<script>
	import ImCamera from '@/api/camera'
	import HeadImage from '@/components/common/HeadImage'
	export default {
		name: "localVideo",
		components: {
			HeadImage
		},
		data() {
			return {
				stream: null,
				isReady: false,
				isFacing: true
			}
		},
		props: {
			userInfo: {
				type: Object
			},
			width: {
				type: Number
			},
			height: {
				type: Number
			},
		},
		methods: {
			open(stream) {
				this.stream = stream;
				// 显示本地视频
				this.$refs.video.srcObject = stream;
				this.$refs.video.muted = true;
				this.$refs.video.play().catch(() => {
					console.log("本地流播放异常")
				});
				this.isReady = !!stream;
			},
			setMicroPhone(isEnable) {
				this.stream.getTracks().forEach((track => {
					if (track.kind === 'audio') {
						track.enabled = isEnable;
					}
				}))
			}
		},
		computed: {
			vw() {
				if (!this.isReady || !this.userInfo.isCamera) {
					return 0
				} else {
					return '100%'
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.local-video {
		position: relative;
		width: 100%;
		height: 100%;
		background-color: darkgray;
		cursor: pointer;
		
		.head-image{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
		
		.video {
			position: relative;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

	}
</style>